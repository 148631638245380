import './App.css'
import Header from './components/Header'
import NotFound from './components/NotFound'
import Base from './components/Base'
import Search from './components/singleSpa/Usermanagement'
import Budget from './components/singleSpa/Budget'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { RBACRequest } from "@bjep-extmod/permission-request";

import './stylesheet.css'

const App = (props) => {
  const permission = new RBACRequest();
  const [usagePermission, setUsagePermission] = useState(false)

  useEffect(() => {
    permission.can(
      props.accessToken,
      "platform-backend",
      "usage"
    ).then(resp => setUsagePermission(resp.getValue()))
  }, [])

  return (usagePermission ?
    <Router>
      <Header sitename={process.env.REACT_APP_SITENAME} />
      <Routes>
        <Route path='/' element={<Base />}>
          <Route path='/budget/*' element={<Budget />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router> : <div style={{ width: "100vw", textAlign: "center", marginTop: "10%" }}>Forbidden</div>
  )
}

export default App
