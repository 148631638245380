import React from "react"
import ReactDOM from "react-dom"
import singleSpaReact from "single-spa-react"
import Root from "./App"

const mountId = "bjep-platform-backend"

const mountpoint = () => {
  let cont = document.getElementById(mountId)
  if (!cont) {
    cont = document.createElement("div")
    cont.id = mountId
    document.body.appendChild(cont)
  }

  return cont
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  domElementGetter: mountpoint,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

