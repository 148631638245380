import React from 'react'
import SideBar from './sidebar/SideBar'
import { Outlet } from 'react-router-dom'

class Base extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      isMobile: true
    }

    this.previousWidth = -1
  }

  updateWidth() {
    const width = window.innerWidth
    const widthLimit = 576
    const isMobile = width <= widthLimit
    const wasMobile = this.previousWidth <= widthLimit

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile
      })
    }

    this.previousWidth = width
  }

  componentDidMount() {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth.bind(this))
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <div id='backendContent' className='App wrapper'>
        <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
        <Outlet />
      </div>
    )
  }
}

export default Base
