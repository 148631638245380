import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuildingColumns
} from '@fortawesome/free-solid-svg-icons'
import SubMenu from './SubMenu'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const SideBar = (props) => {
  const navList = [
    { label: 'Budgetverwaltung', icon: faBuildingColumns, link: '/budget' },
  ]
  return (
    <div className={classNames('sidebar', { 'is-open': props.isOpen })}>
      <Nav className='flex-column pt-2'>
        {navList.map(navItem => (
          navItem.list
            ? (
              <SubMenu
                key={navItem.link}
                title={navItem.label}
                icon={navItem.icon}
                items={navItem.items}
              />
            )
            : (
              <Nav.Item key={navItem.link + 'item'} className='active'>
                <Nav.Link as={Link} to={navItem.link}>
                  <FontAwesomeIcon icon={navItem.icon} className='mr-2' />
                  {navItem.label}
                </Nav.Link>
              </Nav.Item>
            )
        ))}
      </Nav>
    </div>
  );

}

export default SideBar
