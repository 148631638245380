import React from 'react';

const NotFound = (props) => {
  return <div className="container">
    <h1 className="alert alert-primary p-4">this page is somewhere lost in space and time</h1>
    <h3 className="alert alert-danger p-4">... but not here !!! </h3>
    <h5 className="alert alert-warning p-4">hush, hush... go and search somewhere else</h5>
  </div>
}

export default NotFound
