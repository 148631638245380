import React from 'react'
import jwt_decode from "jwt-decode";

const Header = (props) => {
  let db;
  let token;
  const requestDb = window.indexedDB.open("epsoa");
  requestDb.onsuccess = (event) => {
    db = event.target.result;
    if (db.objectStoreNames.contains("authorization")) {
      const transaction = db.transaction(["authorization"]);
      const objectStore = transaction.objectStore("authorization")
      const reqToken = objectStore.get("token")
      reqToken.onerror = () => {
        alert("login not successfull")
      };
      reqToken.onsuccess = () => {
        token = reqToken.result?.accessToken
        var element = document.getElementById("mybjeElement");
        if (element) {
          element.setAttribute("data-mybje-panel-access-token", token)
        }
      };
    }
  }

  return (
    <header className='header-main'>
      <div className='container-fluid m-0 p-0'>
        <div className='row align-items-center header-row m-0'>
          <div className='col-12 header-status-bar-top d-flex justify-content-end'></div>
          <div className='col-2 header-logo-col'>
            <div className='header-logo-main'>
              <a className='header-logo-main-link' href='/' title='Zur Startseite wechseln'>
                <img src='https://www.busch-jaeger-excellence.de/assets/template/img/bje-logo-claim.png' />
                <h4 style={{ textTransform: 'uppercase' }}>{props.sitename}</h4>
              </a>
            </div>
          </div>
          <div className='col-10 header-nav-row'>
            <div className='col-9 nav-main order-sm-1'>
              <div className='main-navigation' id='mainNavigation' data-flyout-menu='true'>
                <div className='container'>
                  <nav className='nav main-navigation-menu' itemScope='itemScope' itemType='http://schema.org/SiteNavigationElement'>
                    <a className='nav-link main-navigation-link' href='X' itemProp='url' data-flyout-menu-trigger='a515ae260223466f8e37471d279e6406' title='X'>
                      {/* <div className='main-navigation-link-text'>
                        <span itemProp='name'>X</span>
                      </div> */}
                    </a>
                  </nav>
                </div>
                <div className='navigation-flyouts'>
                  <div className='navigation-flyout' data-flyout-menu-id='a515ae260223466f8e37471d279e6406'>
                    <div className='container'>
                      <div className='row navigation-flyout-bar'>
                        <div className='col'>
                          <div className='navigation-flyout-category-link'>
                            <a className='nav-link' href='X' itemProp='url' title='X'>Zur Kategorie X
                              <span className='icon icon-arrow-right icon-primary' />
                            </a>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <div className='navigation-flyout-close js-close-flyout-menu'>
                            <span className='icon icon-x' />
                          </div>
                        </div>
                      </div>
                      <div className='row navigation-flyout-content'>
                        <div className='col'>
                          <div className='navigation-flyout-categories'>
                            <div className='row navigation-flyout-categories is-level-0'>
                              <div className='col-3 navigation-flyout-col'>
                                <a className='nav-item nav-link navigation-flyout-link is-level-0' href='https://{{domain}}/{{mainnavigation[0].Translated.Name}}/Damen/' itemProp='url' title='Damen'>
                                  <span itemProp='name'>Damen</span>
                                </a>
                                <div className='navigation-flyout-categories is-level-1' />
                              </div>
                              <div className='col-3 navigation-flyout-col'>
                                <a className='nav-item nav-link navigation-flyout-link is-level-0' href='https://{{domain}}/{{mainnavigation[0].Translated.Name}}/Herren/' itemProp='url' title='Herren'>
                                  <span itemProp='name'>Herren</span>
                                </a>
                                <div className='navigation-flyout-categories is-level-1' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 header-status-bar'>
            <div className='d-none d-sm-block col-lg-9 cms-breadcrumb' />
            <div className='col-lg-3 col-12 status-bar_header-actions'>
              <div className='row no-gutters'>
                <div className='col d-sm-none menu-burger-mobile'>
                  <div className='menu-button'>
                    <button className='btn nav-main-toggle-btn header-actions-btn' type='button' data-offcanvas-menu='true' aria-label='Menü'>
                      <span className='icon icon-stack' />
                    </button>
                  </div>
                </div>
                <div className='col-auto d-sm-none'>
                  <div className='search-toggle'>
                    <button className='btn header-actions-btn search-toggle-btn js-search-toggle-btn collapsed' type='button' data-toggle='collapse' data-target='#searchCollapse' aria-expanded='false' aria-controls='searchCollapse' aria-label='Suchen'>
                      <span className='icon icon-search' />
                    </button>
                  </div>
                </div>
                <div className='col-auto' />
                <div className='col-auto'>
                  <div className='account-menu'>
                    <div className='dropdown'>
                      <button className='btn account-menu-btn header-actions-btn' type='button' id='accountWidget' data-offcanvas-account-menu='true' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' aria-label='Mein EP-Profil' title='Mein EP-Profil'>
                        <span className='icon icon-avatar' />
                        <span className='d-none d-sm-inline'>Mein EP-Profil</span>
                      </button>
                      <div className='dropdown-menu dropdown-menu-right account-menu-dropdown js-account-menu-dropdown' aria-labelledby='accountWidget'>
                        <button className='btn btn-light offcanvas-close js-offcanvas-close btn-block sticky-top'>
                          <span className='icon icon-x icon-sm' />
                          Menü schließen
                        </button>
                        <div className='offcanvas-content-container'>
                          <div className='account-menu'>
                            <div className='dropdown-header account-menu-header'>Mein EP-Profil</div>
                            <div className='account-menu-links'>
                              <div className='header-account-menu'>
                                <div className='card account-menu-inner'>
                                  <div className='list-group list-group-flush account-aside-list-group'>
                                    <a href='https://{{domain}}/account/profile' title='Persönliches Profil' className='list-group-item list-group-item-action account-aside-item'>Persönliches Profil</a>
                                  </div>
                                  <div className='card-footer account-aside-footer' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-auto' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

  )
}
export default Header
